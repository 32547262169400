.top_header_title{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    h1{
        font-size: 20px;
        line-height: 20px;
        margin: 0;
        color: #37384f;
    }
}