.subscription{
    padding: 25px 40px;
font-size: 25px;
font-weight: 500;
display: flex;
justify-content: space-between;
&_checkbox{
    height:25px;
    width: 25px;

}
}